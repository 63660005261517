@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .exg-timepicker-style-common {
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
            .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
                border-color: mat.get-color-from-palette($primary-palette, 'darker-contrast') !important;
            }
        }

        .mat-focused {
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
                .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
                    border-color: mat.get-color-from-palette($primary-palette) !important;  
                }
            }

            .mat-mdc-form-field-required-marker {
                color: mat.get-color-from-palette($primary-palette) !important;
            }
        }
    }

    .exg-timepicker-style-common .ng-touched.ng-invalid {

        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: mat.get-color-from-palette($warn-palette) !important;
        }

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
            color: mat.get-color-from-palette($warn-palette) !important;
        }

        .mat-mdc-form-field-required-marker {
            color: mat.get-color-from-palette($warn-palette) !important;
        }
    }

    .timepicker {

        .timepicker__body {
            background-color: mat.get-color-from-palette($accent-palette, 'default-contrast') !important;
        }

        .clock-face {
            background-color: rgba(mat.get-color-from-palette($accent-palette), 0.1) !important;
        }

        .timepicker__actions {
            justify-content: space-between;
            background-color: mat.get-color-from-palette($accent-palette, 'default-contrast') !important;
        }

        .timepicker__header {
            background-color: mat.get-color-from-palette($primary-palette) !important;  
        }
    
        .clock-face__clock-hand {
            background-color: mat.get-color-from-palette($primary-palette) !important;  
    
            &::after {
                background-color: mat.get-color-from-palette($primary-palette) !important;  
            }
    
            &::before {
                border-color: mat.get-color-from-palette($primary-palette) !important;  
            }
        }
    
        .clock-face__number {
            > span.active {
                background-color: mat.get-color-from-palette($primary-palette) !important;  
            }
        }
    }
}
