@import 'variables';

.mdc-notched-outline__leading {
    border-top-left-radius: $controls-border-radius !important;
    border-bottom-left-radius: $controls-border-radius !important;
}

.mdc-notched-outline__trailing {
    border-top-right-radius: $controls-border-radius !important;
    border-bottom-right-radius: $controls-border-radius !important;
}