$primary: #00b5d5;
$primary-light: #62e7ff;
$primary-dark: #0085a4;
$primary-contrast: #fff;
$primary-light-contrast: #fff;
$primary-dark-contrast: #E5E9EB;

$accent: #212121;
$accent-light: #484848;
$accent-dark: #000;
$accent-alternative: #13cf71;
$accent-contrast: #fff;
$accent-light-contrast: #fff;
$accent-dark-contrast: #fff;

$warn: #FF4158;
$warn-light: #ff5a3c;
$warn-dark: #b20000;
$warn-contrast: #fff;
$warn-light-contrast: #fff;
$warn-dark-contrast: #fff;

$menu_height: 5.8rem;
$footer_height: 4.1rem;

%container {
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
}

%left-container {
    width: 97.5vw;
    margin-left: auto;
}

$controls-border-radius: 4px;
$controls-button-border-radius: 6px;
$controls-chip-border-radius: 16px;

@mixin ellipsis($lineCount) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
}

$mediaDesktop: 1024px;
$mediaTablet: 768px;
$mediaMobile: 600px;
$mediaBiggest: 1400px;
