@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .primary {
        color: mat.get-color-from-palette($primary-palette);  
    }

    .accent {
        color: mat.get-color-from-palette($accent-palette);  
    }

    .warn {
        color: mat.get-color-from-palette($warn-palette);  
    }
}
