
.exg-dialog-container {
    /* override dialog max-width */
    max-width: 90vw !important;

    /* remove padding and make radius border */
    .mat-dialog-container {
        padding: 0;
        border-radius: 4px;
    }
}

.cdk-overlay-container {
    /* fix flickering on tap on mobile */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/* dialog fullscreen styles (used for mobiles) */
.exg-fullscreen-dialog-container {
    max-width: 100vw !important;

    .mat-dialog-container {
        padding: 0;
        border-radius: 3px;
    }

    @media (max-width: 800px) {
        width: 100%;
        height: 100%;

        .mat-dialog-container {
            border-radius: 0;
        }
    }
}

/* make dialog backdrop darker */
.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}

/* make dialog background transparent */
.exg-dialog-hide-background .mat-dialog-container {
    background: transparent;
}
