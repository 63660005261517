
.exg-snackbar {
    background-color: rgba(90, 92, 123, 0.88);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px !important;
}

.mat-snack-bar-container {
    color: white !important;
    font-size: 1.4rem;
}
