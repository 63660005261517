@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .exg-textbox-style-common {
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
            .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
                border-color: mat.get-color-from-palette($primary-palette, 'darker-contrast') !important;
            }
        }

        .mat-focused {
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
                .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
                    border-color: mat.get-color-from-palette($primary-palette) !important;  
                }
            }

            .mat-mdc-form-field-required-marker {
                color: mat.get-color-from-palette($primary-palette) !important;
            }
        }
    }

    .exg-prefix-placeholder {
        color: rgba($color: mat.get-color-from-palette($accent-palette), $alpha: 0.87);
    }

    .mat-form-field-hide-placeholder {
        .exg-prefix-placeholder {
            color: rgba($color: mat.get-color-from-palette($accent-palette), $alpha: 0.6);
        }
    }

    .exg-textbox-style-common .ng-touched.ng-invalid {

        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: mat.get-color-from-palette($warn-palette) !important;
        }

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
            color: mat.get-color-from-palette($warn-palette) !important;
        }

        .mat-mdc-form-field-required-marker {
            color: mat.get-color-from-palette($warn-palette) !important;
        }

        .exg-prefix-placeholder {
            color: rgba($color: mat.get-color-from-palette($warn-palette), $alpha: 0.87);
        }
    }
}
