@use './apply-theme-styling.scss' as themeStyling;

@use '@angular/material' as mat;
@import 'variables';

@include mat.core();

$exg-theme-primary-colors: ( 100: $primary-light, 500: $primary, 600: $primary-dark, contrast: ( 100: $primary-light-contrast, 500: $primary-contrast, 600: $primary-dark-contrast ) );
$exg-theme-accent-colors: ( 100: $accent-light, 500: $accent, 600: $accent-dark, contrast: ( 100: $accent-light-contrast, 500: $accent-contrast, 600: $accent-dark-contrast ) );
$exg-theme-warn-colors: ( 100: $warn-light, 500: $warn, 600: $warn-dark, contrast: ( 100: $warn-light-contrast, 500: $warn-contrast, 600: $warn-dark-contrast ) );

$exg-app-primary: mat.define-palette($exg-theme-primary-colors, 500, 100, 600);
$exg-app-accent: mat.define-palette($exg-theme-accent-colors, 500, 100, 600);
$exg-app-warn: mat.define-palette($exg-theme-warn-colors, 500, 100, 600);

$exg-app-theme: mat.define-light-theme((
  color: (
      primary: $exg-app-primary,
      accent: $exg-app-accent,
      warn: $exg-app-warn
  ),
  density: -3,
));

/* Emit theme-dependent styles for common features used across multiple components. */
@include mat.core-theme($exg-app-theme);
@include mat.all-component-themes($exg-app-theme);
@include themeStyling.apply($exg-app-theme);

.dark-theme {
    $exg-theme-primary-colors: ( 100: $primary-light, 500: $primary, 600: $primary-dark, contrast: ( 100: $primary-light-contrast, 500: $primary-contrast, 600: #eaeaea1f ) );
    $exg-theme-accent-colors: ( 100: $accent-light-contrast, 500: $accent-contrast, 600: $accent-dark-contrast, contrast: ( 100:  $accent-light, 500: $accent, 600: $accent-dark ) );
    $exg-theme-warn-colors: ( 100: $warn-light, 500: $warn, 600: $warn-dark, contrast: ( 100: $warn-light-contrast, 500: $warn-contrast, 600: $warn-dark-contrast ) );
    
    $exg-app-primary: mat.define-palette($exg-theme-primary-colors, 500, 100, 600);
    $exg-app-accent: mat.define-palette($exg-theme-accent-colors, 500, 100, 600);
    $exg-app-warn: mat.define-palette($exg-theme-warn-colors, 500, 100, 600);
    
    $exg-app-theme: mat.define-dark-theme((
    color: (
        primary: $exg-app-primary,
        accent: $exg-app-accent,
        warn: $exg-app-warn
    ),
        density: -3,
    ));
    
    @include mat.all-component-colors($exg-app-theme);
    @include themeStyling.apply($exg-app-theme);
}