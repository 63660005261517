@import "../styles/variables.scss";
/* common style to apply to any element to visualize it as disabled */
.exg-disabled {
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
}

.error {
    color: $warn;
}

.fade-in {
    animation: fadeIn ease 1s;
}

.fade-out {
    animation: fadeOut ease 2s;
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
